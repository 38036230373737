import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import striptags from "striptags"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PageTemplate = ({ data: { post } }) => {
  if (!post) {
    return
  }

  return (
    <Layout>
      <Seo title={parse(post.title)} description={striptags(post.content)} />
      <article
        className="entry"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline" className='entry-title'>{parse(post.title)}</h1>
        </header>

        {!!post.content && (
          <section itemProp="articleBody" className='entry-content'>{parse(post.content)}</section>
        )}
      </article>
      {!!post.childPages.nodes.length && (
        <>
          <div className='spacer'></div>
          <ol className='article-list'>
            {post.childPages.nodes.map(post => (
              <li key={post.id}><article>
                <h2 className="entry-title">{parse(post.title)}</h2>
                <section itemProp="articleBody" className='entry-content'>{parse(post.content)}</section>
              </article></li>
            ))}
          </ol>
        </>
      )}
    </Layout >
  )
}

<iframe style="border: 0; width: 350px; height: 470px;" src="https://bandcamp.com/EmbeddedPlayer/album=3465366140/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless><a href="https://audiodidakt.bandcamp.com/album/homage-sic-transit-gloria">Homage - Sic Transit Gloria by Audiodidakt</a></iframe>

export default PageTemplate

export const pageQuery = graphql`
  query PageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      childPages: wpChildren {
        nodes {
          ... on WpPage {
            id
            content
            title
          }
        }
      }
    }
  }
`
